import React, { useState, useContext } from "react"

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ScrollToTop from "../../util/scrolltotop"
import axios from "axios"
import Auth from "@aws-amplify/auth"
import ReactGA from "react-ga"

import AuthNav from "../../components/auth-nav"

import Home from "./home"
import Campaigns from "./campaigns";
import Campaign from "./campaign";
import Channels from "./channels";
import Channel from "./channel";
import URLs from "./urls";
import Settings from "./settings"
import Teams from "./teams"

import UserContext from "../../context/user"

const AuthRouter = () => {

    let [token, setToken] = useState(false)
    const [Auth_user, setAuth_user] = useContext(UserContext);

    React.useEffect(() => {

        ReactGA.initialize('UA-222903625-1');

        Auth.currentSession().then(auth_user => {

            axios.interceptors.request.use(async (config) => {

                // check to see if axios is going to conflict with our document uploader
                if (config.url.indexOf("markcrm-upload-test.s3.") > -1) {
                    return config
                }

                // this isn't doc uploader, proceed

                config.headers.Authorization = auth_user.idToken.jwtToken;
                return config;
            });

            setToken(auth_user.idToken.jwtToken)

        })

    }, [])

    if (token) {

        return (
            <>
                <style>
                    {`
                    html, body {
                        background-color: rgb(248, 249, 250) !important;
                    }
                    `}
                </style>

                <Router>

                    <AuthNav />
                    <div className="container-fluid m-0 p-0 bg-light">
                        <div className="row m-0">
                            <div className="col-12">

                                <ScrollToTop>
                                    <Switch>
                                        <Route exact path="/" component={Home} />
                                        <Route path="/builder" component={Home} />
                                        <Route exact path="/campaigns" component={Campaigns} />
                                        <Route path="/campaigns/:id" component={Campaign} />
                                        <Route exact path="/channels" component={Channels} />
                                        <Route path="/channels/:id" component={Channel} />
                                        <Route exact path="/urls" component={URLs} />
                                        <Route path="/urls/:id" component={Home} />
                                        <Route path="/settings" component={Settings} />
                                        <Route path="/teams" component={Teams} />
                                    </Switch>
                                </ScrollToTop>

                            </div>
                        </div>
                    </div>

                </Router>
            </>
        )
    } else {
        return (
            <>Loading...</>
        )
    }
};

export default AuthRouter;