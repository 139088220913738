import React from "react"

import {
    useLocation,
    Link
} from "react-router-dom";

import Logout from "../pages/auth/logout";


import {ReactComponent as LogoColor} from "../assets/logo-bug.svg"

const AuthNav = () => {

    const location = useLocation()

    React.useEffect(() => {

    }, [])

    return (
        <>

            <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm p-0 m-0">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/" >
                        <LogoColor style={{ width: "100%", maxHeight: "30px" }} />
                    </Link>
                    <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse ms-md-2" id="navbarText">
                        <ul className="navbar-nav me-auto p-0 m-0">
                            <li className="nav-item">
                                <Link className={"nav-link " + (location.pathname.includes("builder") || location.pathname == "/" ? "active" : "")} to="/builder">Builder</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (location.pathname.includes("campaigns") ? "active" : "")} to="/campaigns">Campaigns</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (location.pathname.includes("channels") ? "active" : "")} to="/channels">Channels</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"nav-link " + (location.pathname.includes("urls") ? "active" : "")} to="/urls">URLs</Link>
                            </li>
                        </ul>
                        <span className="navbar-text">
                            <Logout />
                        </span>
                    </div>
                </div>
            </nav>
    
        </>
    )
};

export default AuthNav;