import React, { useState, useContext } from "react"
import axios from "axios"
import UserContext from "../../context/user"
import { Auth } from 'aws-amplify';
import { Link } from "react-router-dom"
import ReactGA from "react-ga"

const Settings = () => {

    let [loading, setLoading] = useState(false)
    let [view, setView] = useState("settings")

    const [Auth_user, setAuth_user] = useContext(UserContext);

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <div className="container-fluid m-0 py-5 p-0 p-md-3bg-light">
            <div className="row">
                <div className="col-12">

                    <div className="container m-0 p-0 mx-auto">
                        <div className="row">
                            <div className="col-12">

                                <div className="container-fluid m-0 py-3  shadow-sm bg-white">
                                    <div className="row">
                                        <div className="col-12 mb-3 md-md-0 col-md-4 col-lg-2">

                                            <ul className="navbar-nav me-auto p-0 m-0">
                                                <li className="nav-item">
                                                    <span className={"nav-link right " + ((view == "settings") ? "active" : "")} onClick={e => setView("settings")}>Settings</span>
                                                </li>
                                                <li className="nav-item">
                                                    <span className={"nav-link right " + ((view == "invites") ? "active" : "")} onClick={e => setView("invites")}>Invites</span>
                                                </li>
                                                {["owner", "admin", "user"].indexOf(Auth_user.default_team.role) > -1 &&
                                                    <li className="nav-item">
                                                        <span className={"nav-link right " + ((view == "team") ? "active" : "")} onClick={e => setView("team")}>Team <span class="badge rounded-pill bg-success fw-normal px-3 ms-2">{Auth_user.default_team.name}</span></span>
                                                    </li>
                                                }
                                            </ul>

                                        </div>
                                        <div className="col-12 mb-3 md-md-0 col-md-8 col-lg-10 ps-5 pt-3 text-left">

                                            {view == "settings" ? <SettingsView /> : ""}
                                            {view == "team" && ["owner", "admin", "user"].indexOf(Auth_user.default_team.role) > -1 ? <TeamView /> : ""}
                                            {view == "invites" ? <InvitesView /> : ""}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )

}

export default Settings;


const SettingsView = () => {

    const [Auth_user, setAuth_user] = useContext(UserContext);

    let [password, setPassword] = useState({
        "currentPassword": "",
        "newPassword": "",
        "newPassword2": ""
    })

    let [passwordChangeStatus, setPasswordChangeStatus] = useState({
        "status": "",
        "msg": ""
    })

    React.useEffect(() => {

    }, [])

    const changePassword = () => {

        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, password.currentPassword, password.newPassword2);
            })
            .then(data => {

                setPasswordChangeStatus({
                    "status": "success",
                    "msg": "Successfully updated password."
                })

            }).catch(err => {

                setPasswordChangeStatus({
                    "status": "error",
                    "msg": err.message
                })

            });

    }

    const changePasswordField = (e, field) => {

        password = { ...password }
        password[field] = e.target.value
        setPassword(password)

    }

    let [consentDeleteCheck, setConsentDeleteCheck] = useState(false)
    let [deletingAccount, setDeletingAccount] = useState(false)

    const consentDelete = () => {
        setConsentDeleteCheck((consentDeleteCheck) ? false : true)
    }

    const deleteAccount = () => {

        setDeletingAccount(true)
        axios.delete(window.REACT_APP_API_ENDPOINT + "/account").then((res) => {
            
            // user has been deleted, now sign out.
            Auth.signOut().then(response => {    
                window.location.href = "/"
            });

        })
        
    }

    return (
        <div className="container-fluid col-12 col-md-8 ms-0 me-auto pb-5">
            <h2>Settings</h2>

            <h5 className="mt-4">Email</h5>
            <div className="col-12 py-2">
                <input
                    id="email"
                    type="email"
                    value={Auth_user.email}
                    disabled="disabled"
                    placeholder="Email"
                    className="form-control p-2 ps-3 rounded-pill bg-light" />
            </div>

            <h5 className="mt-4">Change password</h5>
            <div className="col-12 py-2">
                <input
                    id="currentPassword"
                    type="password"
                    value={password.currentPassword}
                    onChange={e => changePasswordField(e, "currentPassword")}
                    placeholder="Current password"
                    required
                    className="form-control p-2 ps-3 rounded-pill bg-light" />
            </div>
            <div className="col-12 py-2">
                <input
                    id="newPassword"
                    type="password"
                    value={password.newPassword}
                    onChange={e => changePasswordField(e, "newPassword")}
                    placeholder="New password"
                    required
                    className="form-control p-2 ps-3 rounded-pill bg-light" />
            </div>
            <div className="col-12 py-2">
                <input
                    id="newPassword2"
                    type="password"
                    value={password.newPassword2}
                    onChange={e => changePasswordField(e, "newPassword2")}
                    placeholder="Re-enter new password"
                    required
                    className="form-control p-2 ps-3 rounded-pill bg-light" />
            </div>

            <div className={"col-12 mt-3 " + ((passwordChangeStatus.status) ? "" : "d-none")}>
                <div className={"alert rounded-pill ps-3 py-2 " + ((passwordChangeStatus.status == "success") ? "alert-success" : "alert-danger")}>
                    {passwordChangeStatus.msg}
                </div>
            </div>

            <button type="submit" disabled={(password.currentPassword && password.newPassword && password.newPassword2) ? false : true} onClick={e => changePassword()} className={"btn btn-secondary btn-md text-white rounded-pill mt-2 px-4"} >
                <span>Update</span>
            </button>


            <h5 className="mt-5 d-none">Setup Multi-factor Authentication (recommended)</h5>

            <h5 className="mt-5">Permanently delete account</h5>
            <p>Delete all saved data and close account. This action cannot be undone.</p>

            {!deletingAccount ? (
                <>
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            id="deleteAccountCheck"
                            type="checkbox"
                            onChange={e => consentDelete()}
                            value={consentDeleteCheck} />
                        <label class="form-check-label" for="deleteAccountCheck">
                            I acknowledge by deleting my account I am also deleting all teams where I am the owner. I am also deleting all data associated with the teams that I own.
                        </label>
                    </div>

                    <button type="submit" onClick={e => deleteAccount()} disabled={consentDeleteCheck ? false : true} className={"btn btn-danger btn-md text-white rounded-pill mt-2 px-4"} >
                        <span>Delete account</span>
                    </button>
                </>
            ) : (
                <span className="text-danger">
                    Deleting your account...
                </span>
            )
            }

        </div>
    )

}

const InvitesView = () => {

    let [invites, setInvites] = useState()
    let [loading, setLoading] = useState(true)

    React.useEffect(() => {
        getInvites()
    }, [])

    const getInvites = () => {
        setLoading(true)
        axios.get(window.REACT_APP_API_ENDPOINT + "/invites").then((res) => {
            setInvites(res.data)
            setLoading(false)
        })
    }

    const acceptInvite = (e, id) => {

        setLoading(true)
        axios.put(window.REACT_APP_API_ENDPOINT + "/invites/accept", {
            invite: id
        }).then((res) => {
            alert("Invited accepted")
            getInvites();
        })

    }

    return (
        <div className="container-fluid col-12 col-md-8 ms-0 me-auto pb-5">
            <h2>My Invites</h2>

            {loading ?
                <div className="mt-2">
                    Loading...
                </div>
                :
                <>
                    {invites.length > 0 ?
                        <table className={"table"}>
                            <thead>
                                <tr>
                                    <th scope="col" className="fw-light"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {invites && invites.map((item, index) => {
                                    return (
                                        <tr>
                                            <td><b className="">{item.requestor}</b> invited you to join <b className="text-secondary">{item.team_name}</b></td>
                                            <td className="text-right"><button type="button" onClick={e => acceptInvite(e, item.id)} class="btn btn-link text-success d-inline m-0 p-0">Accept invite</button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        :
                        <div className="container p-5 text-center text-black-50">
                            No pending invites
                        </div>
                    }
                </>
            }

        </div>
    )

}

const TeamView = () => {

    const [Auth_user, setAuth_user] = useContext(UserContext);

    let [teamName, setTeamName] = useState(Auth_user.default_team.name)

    let [team, setTeam] = useState()
    let [loading, setLoading] = useState(true)

    let [invite, setInvite] = useState({
        "email": "",
        "role": ""
    })

    let [invites, setInvites] = useState()
    let [invitesLoading, setInvitesLoading] = useState(true)

    React.useEffect(() => {

        getTeam()
        getInvites()

    }, [])

    const changeName = (e) => {
        setTeamName(e.target.value)
    }

    const updateName = () => {

        axios.patch(window.REACT_APP_API_ENDPOINT + "/team", {
            field: "name",
            value: teamName
        }).then((res) => {

            alert("updated")

        })

    }

    const getTeam = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/team/members").then((res) => {
            setTeam(res.data)
            setLoading(false)
        })

    }

    const removeTeamMember = (e, id) => {

        setLoading(true)

        axios.delete(window.REACT_APP_API_ENDPOINT + "/team/members/" + id).then((res) => {

            if (Auth_user.sub == id) {
                window.location.href = "/teams"
            } else {
                getTeam()
            }

        })

    }

    const getInvites = () => {

        setInvitesLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/team/invites").then((res) => {
            setInvites(res.data)
            setInvitesLoading(false)
        })

    }

    const changeInvite = (e, field) => {

        invite = { ...invite }
        invite[field] = e.target.value
        setInvite(invite)

    }

    const sendInvite = () => {

        setInvitesLoading(true)

        axios.post(window.REACT_APP_API_ENDPOINT + "/team/invites", {
            email: invite.email,
            role: invite.role
        }).then((res) => {

            invite = { ...invite }
            invite.email = ""
            invite.role = ""
            setInvite(invite)

            getInvites()
        })

    }

    const cancelInvite = (e, id) => {

        setInvitesLoading(true)

        axios.delete(window.REACT_APP_API_ENDPOINT + "/team/invites/" + id).then((res) => {
            getInvites()
        })

    }

    return (
        <div className="container-fluid col-12 col-md-8 ms-0 me-auto pb-5">
            <h2>Team settings</h2>
            <Link to="/teams" className="text-secondary d-none"><i class="bi bi-arrow-left-right me-2"></i> Switch teams</Link>

            <p className="text-black-50">Team ID: {Auth_user.default_team.id}</p>

            <h5 className="mt-5">Team name</h5>
            <div className="container-fluid m-0 p-0 mt-3">
                <div className="row p-0 m-0">
                    <div className="col-9 p-0 m-0">
                        <input
                            id="name"
                            type="text"
                            placeholder="Name"
                            value={teamName}
                            onChange={e => changeName(e)}
                            required
                            disabled={(["owner", "admin"].indexOf(Auth_user.default_team.role) > -1) ? false : true}
                            className="form-control p-2 ps-3 rounded-pill bg-light" />
                    </div>
                    <div className="col-3">
                        {["owner", "admin"].indexOf(Auth_user.default_team.role) > -1 &&
                            <button
                                type="submit"
                                disabled={(!teamName || teamName == Auth_user.default_team.name) ? true : false}
                                onClick={e => updateName()}
                                className={"w-100 btn btn-secondary btn-md text-white rounded-pill px-4"} >
                                <span>Update</span>
                            </button>
                        }
                    </div>
                </div>
            </div>

            {/* <TeamUsage /> */}

            <h5 className="mt-5">Teammates</h5>
            {loading ?
                <div className="mt-2">
                    Loading...
                </div>
                :
                <table className={"table"}>
                    <thead>
                        <tr>
                            <th scope="col" className="fw-light">Email</th>
                            <th scope="col" className="fw-light">Role</th>
                            <th scope="col" className="fw-light">Invite status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {team && team.map((item, index) => {
                            return (
                                <tr>
                                    <td>{item.email}</td>
                                    <td>{item.role}</td>
                                    <td>accepted</td>
                                    <td><button type="button" onClick={e => removeTeamMember(e, item.id)} className={"btn btn-link text-danger d-inline m-0 p-0 " + ((item.role == "owner" || ["owner", "admin"].indexOf(Auth_user.default_team.role) < 0) ? "d-none" : "")}>Remove</button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }

            {["owner", "admin"].indexOf(Auth_user.default_team.role) > -1 &&
                <>
                    <h5 className="mt-5">Invite users</h5>
                    <div className="container-fluid m-0 p-0 mt-3">
                        <div className="row p-0 m-0">
                            <div className="col-6 p-0 m-0">
                                <input
                                    id="user"
                                    type="email"
                                    placeholder="Email"
                                    onChange={e => changeInvite(e, "email")}
                                    required
                                    className="form-control p-2 ps-3 rounded-pill bg-light" />
                            </div>
                            <div className="col-3">
                                <select className="form-select rounded-pill w-100 " required value={invite.role} onChange={e => changeInvite(e, "role")}>
                                    <option value="" selected disabled>Role</option>
                                    <option value="admin">Admin</option>
                                    <option value="user">User</option>
                                    <option value="reader">Reader</option>
                                </select>
                            </div>
                            <div className="col-3">
                                <button
                                    type="submit"
                                    disabled={(invite.email && invite.role) ? false : true}
                                    onClick={e => sendInvite()}
                                    className={"w-100 btn btn-secondary btn-md text-white rounded-pill px-4"} >
                                    <span>Send</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <h5 className="mt-5">Pending invites</h5>
                    {invitesLoading ?
                        <div className="mt-2">
                            Loading...
                        </div>
                        :
                        <>
                            {invites.length > 0 ?
                                <table className={"table"}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="fw-light">Email</th>
                                            <th scope="col" className="fw-light">Role</th>
                                            <th scope="col" className="fw-light">Invite status</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invites && invites.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.email}</td>
                                                    <td>{item.role}</td>
                                                    <td>{item.status}</td>
                                                    <td><button type="button" onClick={e => cancelInvite(e, item.id)} class="btn btn-link text-danger d-inline m-0 p-0">Cancel</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                <div className="container p-5 text-center text-black-50">
                                    No pending invites
                                </div>
                            }
                        </>
                    }
                </>
            }

            {["owner", "admin"].indexOf(Auth_user.default_team.role) > -1 &&
                <TeamKeys />
            }

        </div>
    )

}

const TeamUsage = () => {

    let [loading, setLoading] = useState(true)
    let [count, setCount] = useState(0)

    React.useEffect(() => {

        axios.get(window.REACT_APP_API_ENDPOINT + "/team/usage").then((res) => {
            setCount(res.data.count)
            setLoading(false)
        })

    }, [])

    return (
        <>
            <div className="container-fluid p-0 m-0 mt-5">
                <div className="row p-0 m-0">
                    <div className="col-12 p-0 m-0">
                        <h5>Usage</h5>
                        {loading &&
                            <>Loading usage stats...</>
                        }
                        {!loading &&
                            <span className="text-success">{count} / 10,000 monthly queries</span>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}

const TeamKeys = () => {

    let [loading, setLoading] = useState(true)
    let [visibleSecret, setVisibleSecret] = useState(false)
    let [keys, setKeys] = useState()

    const [Auth_user, setAuth_user] = useContext(UserContext);

    React.useEffect(() => {
        getKeys()
    }, [])

    const getKeys = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/team/keys").then((res) => {
            setKeys(res.data)
            setLoading(false)
        })

    }

    const createKeys = (e) => {

        setLoading(true)

        axios.patch(window.REACT_APP_API_ENDPOINT + "/team/keys").then((res) => {
            getKeys()
        })

    }

    const toggleSecret = (e) => {
        setVisibleSecret((visibleSecret) ? false : true)
    }


    return (
        <>
            <div className="container-fluid p-0 m-0 mt-5">
                <div className="row p-0 m-0">
                    <div className="col-12 p-0 m-0">
                        <h5>API Access Key</h5>

                        {loading &&
                            <>Loading access keys...</>
                        }
                        {!loading &&
                            <>
                                {(keys.access_key_id && keys.access_key_secret) &&
                                    <>
                                        <table className={"table my-4"}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="fw-light">Key ID</th>
                                                    <th scope="col" className="fw-light">Key Secret</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {keys.access_key_id}
                                                    </td>
                                                    <td>
                                                        {visibleSecret && keys.access_key_secret}
                                                        <button onClick={e => toggleSecret(e)} className="btn btn-link d-inline m-0 p-0">{visibleSecret ? "hide" : "show"} secret</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {visibleSecret &&
                                            <>
                                                <p className="d-block mt-3">Quickly connect to the data API with your API access key:</p>
                                                <ol>
                                                    <li>Retrieve your team ID from the top of this page</li>
                                                    <li>Base64 encode your team ID, access key ID, and access key secret in the following format: <span className="text-success">TEAM_ID</span>/<span className="text-success">ACCESS_KEY_ID</span>:<span className="text-success">ACCESS_KEY_SECRET</span></li>
                                                    <li>Authorize your HTTP request using the X-API-Key header with your base64-encoded string from the previous step</li>
                                                </ol>
                                                <p className="d-block mt-2">
                                                    To save you some work, we did it for you:
                                                    <br /><br />
                                                    <b>X-API-Key:</b>
                                                    <input
                                                        type="text"
                                                        placeholder="X-API-Key"
                                                        value={btoa(Auth_user.default_team.id + "/" + keys.access_key_id + ":" + keys.access_key_secret)}
                                                        className="form-control p-2 ps-3 mt-2 rounded-pill bg-light" />

                                                </p>
                                            </>
                                        }

                                    </>
                                }
                                {(!keys.access_key_id || !keys.access_key_secret) &&
                                    <div className="container p-5 text-center text-black-50">
                                        No access key has been created. <button onClick={e => createKeys(e)} className="btn btn-link">Click here to create one</button>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}