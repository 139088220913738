import React, { useState } from "react"
import {
    useLocation
} from "react-router-dom";

import Register from "./register";
import Login from "./login";
import {Helmet} from "react-helmet";


const AuthPage = ({ type }) => {

    const [view, setView] = useState(type)

    const location = useLocation()

    React.useEffect(() => {

        setView(type)

    }, [location.pathname])

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Free campaign URL builder, querystring generator, and collaboration tool | Querystring</title>
        </Helmet>
        <div className="container-fluid bg-white pb-5">
            <div className="row">
                <div className="col-12 pb-5">

                    <div className="container py-5">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                {(view == "signin") ? <Login /> : <Register />}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    )

}

export default AuthPage;
