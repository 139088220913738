import React, {useState} from "react"
import axios from "axios"
import {
    Link
} from "react-router-dom";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga"

const Contact = () => {

    React.useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [])

    let [loading, setLoading] = useState(false)
    let [sent, setSent] = useState(false)
    let [contact, setContact] = useState({
        "email": "",
        "name": "",
        "message": ""
    })

    const submitContact = (e) => {

        e.preventDefault()
        e.stopPropagation()

        setLoading(true)

        axios.post(window.REACT_APP_API_ENDPOINT + "/contact", contact).then(res => {
            setLoading(false)
            setSent(true)
        }).catch(err => {
            alert("There was an error submitting the form")
        })

    }

    const fieldChange = (e, field) => {

        contact = {...contact}
        contact[field] = e.target.value
        setContact(contact)

    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact | Querystring</title>
            </Helmet>
            <div className="container p-0 m-0 mx-auto">
                <div className="row m-0 p-0 pb-5">
                    <div className="col-12 p-0 m-0 py-5 text-center">

                        <h2 className="mb-3">Contact</h2>
                        <p className="col-12 col-md-6 mx-auto p-3">Fill out the form below to say hi.</p>

                        <div className="container-fluid col-10 mt-2">
                            <div className="row ">
                                <div className="col-12 text-center">

                                    {!sent &&
                                    <div className="col-12 col-md-5 mx-auto">

                                        <form onSubmit={e => submitContact(e)}>

                                        <input 
                                            id="name" 
                                            type="text" 
                                            required 
                                            placeholder="Name" 
                                            disabled={(loading) ? "disabled" : ""} 
                                            value={contact.name}
                                            onChange={e => fieldChange(e, "name")}
                                            className="form-control p-2 ps-3 bg-light" />
                                        
                                        <input 
                                            id="email" 
                                            type="email" 
                                            required 
                                            placeholder="Email" 
                                            disabled={(loading) ? "disabled" : ""} 
                                            value={contact.email}
                                            onChange={e => fieldChange(e, "email")}
                                            className="form-control p-2 ps-3 bg-light mt-3" />

                                        <textarea 
                                            id="message" 
                                            required 
                                            placeholder="Message" 
                                            disabled={(loading) ? "disabled" : ""} 
                                            value={contact.message}
                                            onChange={e => fieldChange(e, "message")}
                                            className="form-control p-2 ps-3 bg-light mt-3" />

                                        <button 
                                            type="submit" 
                                            disabled={((!contact.name && !contact.email && !contact.message) || loading) ? "disabled" : ""} 
                                            className="btn btn-secondary btn-md text-white w-100 rounded-pill mt-3">
                                            <span className={"spinner-grow spinner-grow-sm " + ((loading) ? "" : "d-none")} role="status" aria-hidden="true"></span>
                                            {(!loading) ? "Send message" : ""}
                                        </button>

                                        </form>

                                    </div>
                                    }
                                    {sent &&
                                    <div className="col-12 col-md-6 text-center text-success p-4 bg-light rounded-3 mx-auto mt-4">
                                        <h4><i class="bi bi-check-circle me-2"></i> We received your message!</h4>
                                        <p className="m-0 p-0">We'll connect with you shortly.</p>
                                    </div>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
};

export default Contact;