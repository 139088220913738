import React, { useState, useContext } from "react"
import axios from "axios"
import { useHistory, useParams } from "react-router-dom"
import UserContext from "../../context/user"
import ReactGA from "react-ga"
import loading_animation from "../../assets/loading.svg"

import ListURLs from "../../components/list-urls"

const Channel = () => {

    let { id } = useParams();

    let [loading, setLoading] = useState(false)
    let [channel, setChannel] = useState()

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {

        getChannel()
        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [])

    const getChannel = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/channels/" + id).then(res => {

            setLoading(false)
            setChannel(res.data)

        })

    }

    const deleteChannel = () => {

        setLoading(true)

        axios.delete(window.REACT_APP_API_ENDPOINT + "/channels/" + id).then(res => {

            setLoading(false)
            history.push("/channels");

        })

    }

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>{channel && channel.name}</h2>
                                </div>
                                <div className="col-6 text-end">

                                    <EditChannel id={id} />


                                    <button data-bs-toggle="modal" data-bs-target="#channelModal" className={"btn btn-sm btn-outline-danger px-3 py-2 d-inline"}>
                                        <i class="bi bi-trash"></i>
                                    </button>

                                    <div className="modal fade" id="channelModal" tabindex="-1" aria-labelledby="channelModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                                            <div className="modal-content">

                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="channelModalLabel">Delete channel</h5>

                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>

                                                <div className="modal-body text-start">

                                                    Deleting this channel will also delete all associated URLs. <strong>Are you sure you want to continue?</strong>

                                                </div>

                                                <div className="modal-footer">
                                                    <button type="submit" onClick={e => deleteChannel()} data-bs-dismiss="modal" className="btn btn-outline-danger mx-auto mx-md-0">Yes, delete channel</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0 bg-white shadow-sm mt-4">
                            <div className="row">
                                <div className="col-12 p-3">

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12 py-3">
                                                <h7 className="text-primary fw-bold">URLs in this channel</h7>
                                            </div>
                                        </div>
                                    </div>

                                    <ListURLs channel_id={id} />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default Channel;



const EditChannel = (id = null) => {

    let [loading, setLoading] = useState(false)
    let [channel, setChannel] = useState()

    React.useEffect(() => {

        getChannel();

    }, [])

    const getChannel = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/channels/" + id.id).then(res => {

            setLoading(false)
            setChannel(res.data)

        })

    }

    const handleNameChange = (e) => {

        channel = { ...channel }
        channel.name = e.target.value
        setChannel(channel)

    }

    let history = useHistory()

    const EditChannel = (e, key) => {

        e.preventDefault()
        setLoading(true)

        axios.patch(window.REACT_APP_API_ENDPOINT + "/channels/" + id.id, {
            "name": channel.name
        }).then(res => {
            window.location.href = "/channels/" + res.data.id
        })

    }

    return (
        <>


            <button data-bs-toggle="modal" data-bs-target="#channelModal-edit" className={"btn btn-sm btn-outline-primary px-3 py-2 d-inline me-4"}>
                <i class="bi bi-pencil"></i> Edit channel
            </button>


            <div className="modal fade" id="channelModal-edit" tabindex="-1" aria-labelledby="channelModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="channelModalLabel">Edit channel</h5>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {loading && 
                        <div className="modal-body text-center">
                            <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                        </div>
                        }

                        {!loading &&
                            <form onSubmit={e => EditChannel(e)}>

                                <div className="modal-body">

                                    <div className="container-fluid p-0 m-0 ">
                                        <div className="row p-0 m-0">

                                            <div className="col-12 mt-md-0">

                                                <div className="form-floating">

                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        name={"name"}
                                                        value={channel && channel.name}
                                                        required
                                                        placeholder="Channel name"
                                                        onChange={(e) => handleNameChange(e)} />

                                                    <label for="name">Channel name</label>

                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-outline-secondary mx-auto mx-md-0">Edit channel</button>
                                </div>

                            </form>
                        }

                    </div>
                </div>
            </div>

        </>
    )

}