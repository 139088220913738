import React, { useState, useContext } from 'react'
import { Link } from "react-router-dom"
import UserContext from "../../context/user"
import Auth from "@aws-amplify/auth"

const Logout = () => {

    const [user, setUser] = useState({
        username: false,
        isUserSignedIn: false
    });

    const [Auth_user, setAuth_user] = useContext(UserContext);

    React.useEffect(() => {

    })

    const logoutUser = (event) => {

        event.preventDefault()

        Auth.signOut().then(response => {

            const state = { ...user };
            state.username = false;
            state.isUserSignedIn = false;
            setUser(state)

            window.location.href = "/"

        });

    }

    return (
        <>
            <Link to="/teams" className="badge rounded-pill bg-primary fw-normal px-3 py-2 me-2 text-white fs-6 ">{Auth_user.default_team.name}</Link>
            <span className="badge rounded-pill bg-light fw-normal px-3 py-2 me-4 text-success fs-6 ">{Auth_user.default_team.role}</span>
            <div className="btn-group p-0 m-0 pt-2 pb-3 p-md-0 m-md-0">
                <button className="btn btn-light border-0 dropdown-toggle p-0 m-0" style={{ backgroundColor: "#ffffff" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {Auth_user.email} &nbsp;&nbsp;
                </button>
                <ul className="dropdown-menu dropdown-menu-start dropdown-menu-md-end pb-0" aria-labelledby="dropdownMenuButton1">
                    <li><h6 class="dropdown-header">Signed in as</h6></li>
                    <li><span className="dropdown-item disabled pt-0">{Auth_user.email}</span></li>
                    <li><Link to="/settings" className="dropdown-item w-100">Settings</Link> </li>
                    <li><Link to="/teams" className="dropdown-item w-100">Switch teams</Link> </li>
                    <li><hr className="dropdown-divider p-0 m-0" /></li>
                    <li className=""><a href="#" className="dropdown-item w-100 py-2" onClick={logoutUser}>Sign out <i class="bi bi-arrow-bar-right float-end"></i></a> </li>
                </ul>
            </div>
        </>
    )
};

export default Logout