import React, {useState} from 'react'
import { Link } from "react-router-dom"
import Auth from "@aws-amplify/auth"
import ForgotPassword from "./forgot-password"
import ReactGA from "react-ga"

const Login = () => {

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    let [form, setForm] = useState({
        email: "",
        password: "",
        error: false,
        success: false,
        loading: false,
        forgotPassword: false
    })

    const loginUser = (event) => {

        event.preventDefault()

        form = {...form}
        form.loading = true
        form.success = false
        form.error = false
        setForm(form)

        Auth.signIn(form.email, form.password).then((response) => {

            console.log(response)

            form = { ...form }
            form.loading = false
            form.success = "User successfully signed in"
            form.error = false
            setForm(form)

            ReactGA.event({
                category: 'sign_in',
                action: "sign_in",
                value: response.attributes.sub
            });
            ReactGA.set({ userId: response.attributes.sub });

            window.location.href = "/"


        }).catch((error) => {

            form = { ...form }
            form.loading = false
            form.success = false
            form.error = error.message
            setForm(form)

        });

    }

    const fieldChange = (event) => {

        form[event.target.id] = event.target.value
        setForm(form)

    }

    const forgotPassword = (event) => {

        event.preventDefault()

        form = { ...form }
        form.forgotPassword = true
        setForm(form)

    }

    if(!form.forgotPassword){
        return (
            <form className="card px-0 px-md-3 py-4 pb-5 border-0" onSubmit={loginUser}>
                <div className="container">
                    <div className="row pb-3">
                        <div className="col-12 pb-3">
                            <h4>Sign in</h4>
                        </div>
                        <div className="col-12 pb-3">
                            <input id="email" type="email" required placeholder="Email" className="form-control p-2 ps-3  bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.email.value} onChange={fieldChange} />
                        </div>
                        <div className="col-12 pb-3">
                            <input id="password" type="password" placeholder="Password" required className="form-control p-2 ps-3  bg-light" disabled={(form.loading) ? "disabled" : ""} value={form.password.value} onChange={fieldChange} />
                        </div>
                        <div className={"col-12 " + ((form.error) ? "" : "d-none")}>
                            <div className="alert  ps-3 py-2 alert-danger">
                                {form.error}
                            </div>
                        </div>
                        <div className={"col-12 " + ((form.success) ? "" : "d-none")}>
                            <div className="alert  ps-3 py-2 alert-success">
                                {form.success}
                            </div>
                        </div>
                        <div className="col-12">
                            <button type="submit" className={"btn btn-secondary btn-md text-white w-100  " + ((form.loading) ? "disabled" : "")} disabled={(form.loading) ? "disabled" : ""}>
                                <span className={"spinner-grow spinner-grow-sm " + ((form.loading) ? "" : "d-none")} role="status" aria-hidden="true"></span>
                                <span className={(form.loading) ? "d-none" : ""}>Sign in</span>
                                <span className={(form.loading) ? "" : "d-none"}></span>
                            </button>
                        </div>
                        <div className="col-12 pt-4">
                            <a href="#" className="text-secondary" onClick={forgotPassword}>I forgot my password</a>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12 py-3 text-center">
                            <p>Don't have an account? They're free.</p>
                            <Link to="/sign-up" className="btn btn-outline-dark border-2  w-100">Sign up</Link>
                        </div>
                    </div>
                </div>
            </form>
        )
    } else {
        return <ForgotPassword />
    }

}

export default Login