import React from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import ReactGA from "react-ga"

import ScrollToTop from "../../util/scrolltotop"

import GuestNav from "../../components/guest-nav";

import HomeGuest from "./home";
import AuthPage from "./auth";
import Privacy from "./privacy";

import Contact from "./contact";


const GuestView = () => {

    React.useEffect(() => {
        ReactGA.initialize('UA-222903625-1');
    }, [])

    let year = new Date().getFullYear();

    return (
        <>

            <Router>

            <GuestNav />
            
            <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">
                    <div className="col-12 p-0">

                        <ScrollToTop>
                            <Switch>
                                <Route exact path="/" component={HomeGuest} />
                                <Route exact path="/contact" component={Contact} />
                                <Route exact path="/sign-in" render={(e) => <AuthPage type="signin" />}  />
                                <Route exact path="/sign-up" render={(e) => <AuthPage type="signup" />} />
                                <Route exact path="/privacy" render={(e) => <Privacy />} />
                            </Switch>
                        </ScrollToTop>
                            
                    </div>
                </div>
                <div className="row footer m-0 p-0">
                    <div className="col-12 m-0 p-0 py-3 mb-5">

                        <div className="container mx-auto m-0 p-0">
                            <div className="row m-0 p-0">
                                <div className="col-12 text-black-50 text-center">
                                    &copy; {year} Querystring

                                    <Link className="ms-4 me-2 text-black-50" to="/">Build</Link>
                                    <Link className="ms-2 me-2 text-black-50" to="/contact">Contact</Link>
                                    <Link className="ms-2 me-4 text-black-50" to="/privacy">Privacy policy</Link>

                                    Made in <strong>California USA</strong>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            </Router>

        </>

    )
};

export default GuestView;