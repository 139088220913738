const awscognito = {
    Auth: {
        identityPoolId: "us-east-1:bfaca70f-4661-4d4f-89b3-5476e78c3553",
        region: "us-east-1",
        userPoolId: "us-east-1_LzROUQp7r",
        userPoolWebClientId: "3pujsi4e9b5r1kb93hilk1829u",
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    Storage: {
        AWSS3: {
            bucket: 'markcrm-upload-test',
            region: 'us-east-1'
        }
    }
};

export default awscognito;