import React, { useState, useContext } from "react"
import axios from "axios"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import UserContext from "../../context/user"
import _, { values } from "lodash"
import ReactGA from "react-ga"

import loading_animation from "../../assets/loading.svg"

const Channels = () => {

    let [loading, setLoading] = useState(false)

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Channels</h2>
                                </div>
                                <div className="col-6 text-end">
                                    <CreateChannel />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0 bg-white shadow-sm mt-4">
                            <div className="row">
                                <div className="col-12 p-3">
                                    <ListChannels />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default Channels;





const CreateChannel = (props) => {

    let [loading, setLoading] = useState(false)
    let [channel, setChannel] = useState()
    let [parameters, setParameters] = useState([])
    let [name, setName] = useState()
    const [id, setId] = useState(props.id)

    React.useEffect(() => {

    }, [])

    const getChannel = () => {

        if (id) {
            setLoading(true)

            axios.get(window.REACT_APP_API_ENDPOINT + "/channels/" + id).then(res => {

                setLoading(false)
                setName(res.data.name)
                setParameters(res.data.parameters)
                setChannel(res.data)

            })
        }

    }

    const handleNameChange = (e) => {

        setName(e.target.value)

    }

    let history = useHistory()

    const createChannel = (e, key) => {

        e.preventDefault()

        setLoading(true)

        if (id) {

            axios.patch(window.REACT_APP_API_ENDPOINT + "/channels/" + id, {
                "name": name,
                "parameters": parameters
            }).then(res => {
                window.location.href = "/channels"
            })

        } else {

            axios.post(window.REACT_APP_API_ENDPOINT + "/channels", {
                "name": name,
                "parameters": parameters
            }).then(res => {
                window.location.href = "/channels"
            })

        }

    }

    const addParameter = (e) => {

        e.preventDefault()

        parameters = [...parameters]
        parameters.push({
            "key": "",
            "value": ""
        })
        setParameters(parameters)

    }

    const removeParameter = (e, index) => {

        e.preventDefault()

        parameters = [...parameters]
        parameters.splice(index, 1)
        setParameters(parameters)

    }

    const handleFieldChange = (e, key) => {

        parameters = [ ...parameters ]
        _.set(parameters, key, e.target.value)
        setParameters(parameters)

    }

    const loadChannelsTemplate = (e) => {

        e.preventDefault()

        parameters = [...parameters]

        let values = [
            {
                "key": "utm_id",
                "value": ""
            },
            {
                "key": "utm_source",
                "value": ""
            },
            {
                "key": "utm_medium",
                "value": ""
            },
            {
                "key": "utm_campaign",
                "value": ""
            },
            {
                "key": "utm_term",
                "value": ""
            },
            {
                "key": "utm_content",
                "value": ""
            }
        ]

        values.map((item, index) => {
            parameters.push(item)
        })


        setParameters(parameters)


    }


    return (
        <>


            <button className="btn btn-outline-secondary" onClick={e => getChannel(e)} data-bs-toggle="modal" data-bs-target={"#channelModal-" + id}>
                {id ?
                    <>
                        <i className="bi bi-pencil me-1"></i> Edit channel
                    </>
                    :
                    <>
                        <i className="bi bi-plus-lg me-1"></i> Create a new channel
                    </>
                }
            </button>


            <div className="modal fade" id={"channelModal-" + id} tabindex="-1" aria-labelledby="channelModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="channelModalLabel">{id ? "Edit" : "Create"} channel</h5>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>


                        {!loading &&
                            <>

                                <form onSubmit={e => createChannel(e)}>

                                    <div className="modal-body">

                                        <div className="container-fluid p-0 m-0 ">
                                            <div className="row p-0 m-0">

                                                <div className="col-12 mt-md-0 text-start">

                                                    <div className="form-floating">

                                                        <input
                                                            type="text"
                                                            className={"form-control"}
                                                            name={"name"}
                                                            value={name}
                                                            required
                                                            placeholder="Channel name"
                                                            onChange={(e) => handleNameChange(e)} />

                                                        <label for="name">Channel name</label>

                                                    </div>

                                                    <div className="container-fluid m-0 p-0">



                                                        {parameters && parameters.map((item, index) => {
                                                            return (
                                                                <div className="row mt-4" key={index}>
                                                                    <div className="col-5">

                                                                        <div className="form-floating">
                                                                            <input
                                                                                type="text"
                                                                                className={"form-control"}
                                                                                name={"key"}
                                                                                value={parameters[index].key}
                                                                                placeholder="Parameter"
                                                                                onChange={(e) => handleFieldChange(e, "[" + index + "].key")} />
                                                                            <label for="parameter">Parameter</label>
                                                                        </div>

                                                                    </div>
                                                                    <div className={"col-5 me-0 pe-0"}>

                                                                        <div className="form-floating">
                                                                            <input
                                                                                type="text"
                                                                                className={"form-control"}
                                                                                name={"value"}
                                                                                value={parameters[index].value}
                                                                                placeholder="Value"
                                                                                onChange={(e) => handleFieldChange(e, "[" + index + "].value")} />
                                                                            <label for="value">Value</label>
                                                                        </div>


                                                                    </div>
                                                                    <div className={"col-2 m-0 p-0 text-center"}>

                                                                        <button onClick={e => removeParameter(e, index)} className="btn btn-outline-white text-danger" tabindex="-1" style={{ position: "relative", top: "8px" }}>
                                                                            <i className="bi bi-x-lg" style={{ fontSize: "18px" }}></i>
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                    <button onClick={e => addParameter(e)} className="btn btn-sm btn-outline-secondary text-secondary px-3 py-2 d-block d-lg-inline mt-4 me-4">
                                                        <i className="bi bi-plus-lg me-2"></i>
                                                        Add parameter
                                                    </button>

                                                    <button onClick={e => loadChannelsTemplate(e)} className="btn btn-sm btn-outline-secondary text-secondary px-3 py-2 d-block d-lg-inline mt-4">
                                                        <i className="bi bi-plus-lg me-2"></i>
                                                        Add UTM parameters
                                                    </button>


                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                    <div className="modal-footer">
                                        <button type="submit" data-bs-dismiss="modal" className="btn btn-outline-secondary mx-auto mx-md-0">{id ? "Update" : "Create"}  channel</button>
                                    </div>

                                </form>

                            </>
                        }

                        {loading &&
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>
                </div>
            </div>

        </>
    )

}

const ListChannels = () => {

    let [loading, setLoading] = useState(false)
    let [channels, setChannels] = useState()

    React.useEffect(() => {

        getChannels()

    }, [])

    const getChannels = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/channels").then(res => {

            setLoading(false)
            setChannels(res.data)

        })

    }

    const deleteChannel = (e, id) => {

        setLoading(true)

        axios.delete(window.REACT_APP_API_ENDPOINT + "/channels/" + id).then(res => {
            getChannels();
        })

    }

    let [search, setSearch] = useState()

    const searchFilter = (e) => {
        setSearch(e.target.value)
    }

    return (
        <>
            {!loading && channels && channels.length > 0 &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 pb-4 pt-3">

                            <input id="search" type="text" placeholder="Search" className="form-control p-2 ps-3 bg-light" value={search} onChange={e => searchFilter(e)} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <div className="nowrap" style={{
                                overflowX: "auto"
                            }}>

                                <table className={"table table-hover"}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="fw-light">Name</th>
                                            <th className="fw-light">Parameters</th>
                                            <th scope="col" className="fw-light">Last modified by</th>
                                            <th scope="col" className="fw-light">Last modified</th>
                                            <th scope="col" className="fw-light"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {channels && channels.filter(function (channel) {
                                            if(search){
                                                return (
                                                    channel.name.toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1 ||
                                                    JSON.stringify(channel.parameters).toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1
                                                )
                                            } else {
                                                return 1 === 1
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="text-primary">{item.name}</td>
                                                    <td className="text-primary text-break text-wrap">
                                                        {item.parameters.map((item, index) => {
                                                            return (
                                                                <span class="badge bg-light text-dark me-2 fw-normal">{item.key}: {item.value}</span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td>{item.updated_by_email}</td>
                                                    <td>{moment.utc(item.updated_at).local().fromNow()}</td>
                                                    <td className="text-end">

                                                        <CreateChannel id={item.id} />

                                                        <button onClick={e => deleteChannel(e, item.id)} className={"btn btn-sm btn-outline-danger px-3 py-2 d-inline ms-2"}>
                                                            <i class="bi bi-trash"></i>
                                                        </button>

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            }
            {loading &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                        </div>
                    </div>
                </div>
            }
            {!loading && channels && channels.length < 1 &&
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-12 bg-light p-5 text-center">
                            No channels have been created yet.
                            <br />
                            <br />
                            <CreateChannel />
                        </div>
                    </div>
                </div>
            }
        </>
    )

}