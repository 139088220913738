import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import axios from "axios"

// import bootstrap
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.min.js.map'

import "./styles/app.scss"

// context
import UserContext from "./context/user"

// views
import GuestView from "./pages/guest/index"
import AuthView from "./pages/auth/index"

// AWS Cognito / Amplify
import awscognito from "./aws-exports"
import Auth from "@aws-amplify/auth"
import Storage from "@aws-amplify/storage"
Auth.configure(awscognito);
Storage.configure(awscognito);


window.REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


function App() {

  const [Auth_user, setAuth_user] = useState(null);
  const [state, setState] = useState({
    loaded: false
  })

  let [teamLoaded, setTeamLoaded] = useState(false)

  React.useEffect(() => {

    setAuthUser()

  }, []);

  let [error, setError] = useState()

  const setAuthUser = () => {

    Auth.currentAuthenticatedUser().then(auth_user => {

      if (auth_user) {

        setTeamLoaded(false)

        setAuth_user(auth_user.attributes)

        Auth.currentSession().then(auth_session => {

          axios.get(window.REACT_APP_API_ENDPOINT + "/default-team", {
            headers: {
              Authorization: auth_session.idToken.jwtToken
            },
            data: {}
          }).then(res => {

            auth_user.attributes.default_team = res.data
            setAuth_user(auth_user.attributes)
            setTeamLoaded(true)

          }).catch(err => {

          })

        })


      }

      setState({
        loaded: true
      });

    }).catch((e) => {

      setState({
        loaded: true
      });

    });

  };

  return (
    <UserContext.Provider value={[Auth_user, setAuth_user]}>
      <div className="App">

        <div className="container-fluid m-0 p-0">
          <div className="row m-0 p-0">
            <div className="col-12 m-0 p-0">

              {state.loaded ?
                (Auth_user ?
                  (teamLoaded ?
                    <AuthView setAuthUser={setAuthUser} />
                    :
                    <>
                      <TeamLoader />
                    </>
                  )
                  :
                  <GuestView />
                )
                :
                ""}

            </div>
          </div>
        </div>

      </div>
    </UserContext.Provider>
  );
}

const TeamLoader = () => {

  let [warn, setWarn] = useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setWarn(true)
    }, 5000)
  })

  const logoutUser = (event) => {

    event.preventDefault()

    Auth.signOut().then(response => {
      window.location.href = "/"
    });

  }

  return (
    <>

      <style>
        {`
                    html, body {
                        background-color: rgb(248, 249, 250) !important;
                    }
                    `}
      </style>

      Loading...
      <div className={"mt-4 p-3 " + (warn ? "" : "d-none")}>
        Hmmm... this is taking longer than it should. <button onClick={e => logoutUser(e)} className="btn btn-link text-secondary d-inline p-0 m-0">Click here to sign out</button>.
      </div>
    </>
  )

}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);