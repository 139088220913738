import React, { useState, useContext } from "react"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"
import UserContext from "../../context/user"
import MiniBuilder from "../../components/mini-builder"
import ReactGA from "react-ga"

const Home = () => {

    let [loading, setLoading] = useState(false)

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">


                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0">
                            <div className="row">
                                <MiniBuilder auth={true} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default Home;


