import React, { useState } from "react"
import axios from "axios"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import _ from "lodash";

import loading_animation from "../assets/loading.svg"

const ListURLs = (campaign_id = null) => {

    let [loading, setLoading] = useState(false)
    let [URLs, setURLs] = useState()

    React.useEffect(() => {

        getURLs()

    }, [])

    const getURLs = () => {

        setLoading(true)

        let campaign_query = {}

        if (campaign_id.campaign_id) {
            campaign_query = {
                "params": {
                    "campaign": campaign_id.campaign_id
                }
            }
        }

        axios.get(window.REACT_APP_API_ENDPOINT + "/urls", campaign_query).then(res => {

            console.log(res.data)

            setLoading(false)
            setURLs(res.data)

        })

    }

    let history = useHistory()
    const viewRecord = (e, id) => {
        history.push("/urls/" + id);
    }

    let [search, setSearch] = useState()

    const searchFilter = (e) => {
        setSearch(e.target.value)
    }

    const copy = (e, text) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(text)
    }

    return (
        <>
            {!loading && URLs && URLs.length > 0 &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 pb-4 pt-3">

                            <input id="search" type="text" placeholder="Search" className="form-control p-2 ps-3 bg-light" value={search} onChange={e => searchFilter(e)} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <div className="nowrap" style={{
                                overflowX: "auto"
                            }}>

                                <table className={"table table-hover"}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="fw-light">Name</th>
                                            <th scope="col" className="fw-light">Campaign</th>
                                            <th className="fw-light">Parameters</th>
                                            <th scope="col" className="fw-light">URL</th>
                                            <th scope="col" className="text-center"></th>
                                            {/* <th scope="col" className="fw-light">Last modified by</th>
                                            <th scope="col" className="fw-light">Last modified</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {URLs && URLs.filter(function (url) {
                                            if (search) {
                                                return (
                                                    (url.name.toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1 ||
                                                        url.output_url.toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1 ||
                                                        (url.campaign_name ? url.campaign_name : "").toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1 ||
                                                        JSON.stringify(url.parameters).toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1)

                                                )
                                            } else {
                                                return 1 === 1
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <tr style={{ cursor: "pointer" }} onClick={e => viewRecord(e, item.id)}>
                                                    <td className="text-primary" style={{ minWidth: "150px" }}>
                                                    {item.name ?
                                                            <>{item.name}</>
                                                            :
                                                            <span className="text-dark">None</span>
                                                        }

                                                    </td>
                                                    <td className="text-dark">
                                                        {item.campaign ?
                                                            <small><strong>{item.campaign_name}</strong></small>
                                                            :
                                                            <>None</>
                                                        }
                                                    </td>
                                                    <td className="text-primary text-break text-wrap">
                                                        {item.parameters.map((item, index) => {
                                                            return (
                                                                <span class="badge bg-light text-dark me-2 fw-normal">{item.key}: {item.value}</span>
                                                            )
                                                        })}
                                                    </td>
                                                    <td className="text-break text-wrap" style={{ fontSize: "14px" }}>{item.output_url}</td>
                                                    {/* <td>{item.updated_by_email}</td>
                                                    <td>{moment.utc(item.updated_at).local().fromNow()}</td> */}
                                                    <td>
                                                        <button onClick={e => copy(e, item.output_url)} className={"btn btn-sm btn-outline-secondary text-secondary px-3 py-2 me-0 me-md-3 d-block d-md-inline border-0"}>
                                                            <i className="bi bi-clipboard"></i>
                                                        </button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            }
            {loading &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                        </div>
                    </div>
                </div>
            }
            {!loading && URLs && URLs.length < 1 &&
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-12 bg-light p-5 text-center">
                            No URLs have been created yet.
                            <br />
                            <br />
                            <Link to="/builder" className="btn btn-outline-secondary" ><i className="bi bi-plus-lg me-1"></i> Create new URL</Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

export default ListURLs;