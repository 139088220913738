import React, { useState, useContext } from "react"
import axios from "axios"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import UserContext from "../../context/user"
import ReactGA from "react-ga"

import loading_animation from "../../assets/loading.svg"

import ListURLs from "../../components/list-urls"

const URLs = () => {

    let [loading, setLoading] = useState(false)

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>URLs</h2>
                                </div>
                                <div className="col-6 text-end">
                                    <Link to="/builder" className="btn btn-outline-secondary" ><i className="bi bi-plus-lg me-1"></i> Create new URL</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0 bg-white shadow-sm mt-4">
                            <div className="row">
                                <div className="col-12 p-3">
                                    <ListURLs />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default URLs;

