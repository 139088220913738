import React, { useState, useContext } from "react"
import axios from "axios"
import { useHistory, useParams } from "react-router-dom"
import UserContext from "../../context/user"
import ReactGA from "react-ga"
import loading_animation from "../../assets/loading.svg"

import ListURLs from "../../components/list-urls"

const Campaign = () => {

    let { id } = useParams();

    let [loading, setLoading] = useState(false)
    let [campaign, setCampaign] = useState()

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {

        getCampaign()
        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [])

    const getCampaign = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/campaigns/" + id).then(res => {

            setLoading(false)
            setCampaign(res.data)

        })

    }

    const deleteCampaign = () => {

        setLoading(true)

        axios.delete(window.REACT_APP_API_ENDPOINT + "/campaigns/" + id).then(res => {

            setLoading(false)
            history.push("/campaigns");

        })

    }

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>{campaign && campaign.name}</h2>
                                </div>
                                <div className="col-6 text-end">

                                    <EditCampaign id={id} />


                                    <button data-bs-toggle="modal" data-bs-target="#campaignModal" className={"btn btn-sm btn-outline-danger px-3 py-2 d-inline"}>
                                        <i class="bi bi-trash"></i>
                                    </button>

                                    <div className="modal fade" id="campaignModal" tabindex="-1" aria-labelledby="campaignModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                                            <div className="modal-content">

                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="campaignModalLabel">Delete campaign</h5>

                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>

                                                <div className="modal-body text-start">

                                                    Deleting this campaign will also delete all associated URLs. <strong>Are you sure you want to continue?</strong>

                                                </div>

                                                <div className="modal-footer">
                                                    <button type="submit" onClick={e => deleteCampaign()} data-bs-dismiss="modal" className="btn btn-outline-danger mx-auto mx-md-0">Yes, delete campaign</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0 bg-white shadow-sm mt-4">
                            <div className="row">
                                <div className="col-12 p-3">

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-12 py-3">
                                                <h7 className="text-primary fw-bold">URLs in this campaign</h7>
                                            </div>
                                        </div>
                                    </div>

                                    <ListURLs campaign_id={id} />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default Campaign;



const EditCampaign = (id = null) => {

    let [loading, setLoading] = useState(false)
    let [campaign, setCampaign] = useState()

    React.useEffect(() => {

        getCampaign();

    }, [])

    const getCampaign = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/campaigns/" + id.id).then(res => {

            setLoading(false)
            setCampaign(res.data)

        })

    }

    const handleNameChange = (e) => {

        campaign = { ...campaign }
        campaign.name = e.target.value
        setCampaign(campaign)

    }

    let history = useHistory()

    const EditCampaign = (e, key) => {

        e.preventDefault()
        setLoading(true)

        axios.patch(window.REACT_APP_API_ENDPOINT + "/campaigns/" + id.id, {
            "name": campaign.name
        }).then(res => {
            window.location.href = "/campaigns/" + res.data.id
        })

    }

    return (
        <>


            <button data-bs-toggle="modal" data-bs-target="#campaignModal-edit" className={"btn btn-sm btn-outline-primary px-3 py-2 d-inline me-4"}>
                <i class="bi bi-pencil"></i> Edit campaign
            </button>


            <div className="modal fade" id="campaignModal-edit" tabindex="-1" aria-labelledby="campaignModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="campaignModalLabel">Edit campaign</h5>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {loading && 
                        <div className="modal-body text-center">
                            <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                        </div>
                        }

                        {!loading &&
                            <form onSubmit={e => EditCampaign(e)}>

                                <div className="modal-body">

                                    <div className="container-fluid p-0 m-0 ">
                                        <div className="row p-0 m-0">

                                            <div className="col-12 mt-md-0">

                                                <div className="form-floating">

                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        name={"name"}
                                                        value={campaign && campaign.name}
                                                        required
                                                        placeholder="Campaign name"
                                                        onChange={(e) => handleNameChange(e)} />

                                                    <label for="name">Campaign name</label>

                                                </div>


                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-outline-secondary mx-auto mx-md-0">Edit campaign</button>
                                </div>

                            </form>
                        }

                    </div>
                </div>
            </div>

        </>
    )

}