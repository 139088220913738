import React from "react"

import {
    useLocation,
    Link
} from "react-router-dom";


import {ReactComponent as LogoColor} from "../assets/logo.svg"


const GuestNav = () => {

    const location = useLocation()

    React.useEffect(() => {

    }, [])

    const collapse = () => {
        document.getElementById("navbarText").classList.remove('show');
    }

    return (
        <>

            <nav className="navbar navbar-expand-lg navbar-light bg-white py-3 py-lg-0">
                <div className="container-md">
                    <Link className="navbar-brand" onClick={e => collapse()} to="/" style={{ maxWidth: "180px" }}>
                        <LogoColor style={{ width: "100%", maxWidth: "180px"}}/>
                    </Link>                  
                    <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse ms-md-2 text-end" id="navbarText">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 me-md-5">
                            <li className="nav-item" onClick={e => collapse()}>
                                <Link to="/"  className={"nav-link  " + (location.pathname == "/" ? "active" : "")}>Build</Link>
                            </li>
                            {/* <li className="nav-item" onClick={e => collapse()}>
                                <Link to="/parse"  className={"nav-link  " + (location.pathname == "/parse" ? "active" : "")}>Parse</Link>
                            </li> */}
                            <li className="nav-item" onClick={e => collapse()}>
                                <Link to="/contact" className={"nav-link " + (location.pathname.includes("contact") ? "active border-0" : "")}>Contact</Link>
                            </li>
                        </ul>
                        <span className="navbar-text text-end">
                            <Link to="/sign-in" onClick={e => collapse()} className="btn btn-outline-secondary text-secondary px-3 py-2 me-0 me-md-3 d-block d-md-inline">Sign in</Link>
                            <Link to="/sign-up" onClick={e => collapse()} className="btn btn-secondary text-white px-3 py-2 d-block d-md-inline mt-3 mt-md-0">Sign up</Link>
                        </span>
                    </div>
                </div>
            </nav>
    
        </>
    )
};

export default GuestNav;