import React, { useState, useMemo } from "react"
import axios from "axios"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
import _ from "lodash";
import QRCode from "qrcode";

import loading_animation from "../assets/loading.svg"

let count = 0

const MiniBuilder = ({ auth = false }) => {

    let { id } = useParams();
    let [loading, setLoading] = useState(false)

    let [builder, setBuilder] = useState({
        name: "",
        input_url: "",
        parameters: [],
        force_lowercase: true,
        encode_url: true
    })

    let location = useLocation();

    React.useEffect(() => {

        if (id) {
            getURL()
        } else {

            setBuilder({
                name: "",
                input_url: "",
                parameters: [],
                force_lowercase: true,
                encode_url: true
            })

        }

    }, [location])

    const getURL = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/urls/" + id).then(res => {
            setBuilder(res.data)
            setLoading(false)

        })

    }


    const handleFieldChange = (e, key) => {

        builder = { ...builder }
        _.set(builder, key, e.target.value)
        setBuilder(builder)

        buildURL()

        if (key == "input_url") {
            parseURL(e)
        }

    }

    const toggleField = (e, key) => {

        builder = { ...builder }
        builder[key] = (builder[key] ? false : true)
        setBuilder(builder)

        buildURL()

    }

    const parseURL = (e) => {

        builder = { ...builder }
        builder.input_url = e.target.value
        setBuilder(builder)

        if (e.target.value) {

            let querystring = builder.input_url.split("?")[1]

            if (querystring) {

                let fragments = querystring.split("&")

                fragments.map((item, index) => {

                    let parameter = item.split("=")

                    if (parameter[0]) {

                        if (_.find(builder.parameters, { "key": parameter[0] })) {

                            builder.parameters[index]["value"] = parameter[1]

                        } else {

                            if (parameter[1]) {

                                builder.parameters.push({
                                    "key": parameter[0],
                                    "value": parameter[1]
                                })

                            }

                        }

                    }

                })

                setBuilder(builder)

            }

        }

        buildURL()

    }

    const addParameter = (e) => {

        builder = { ...builder }
        builder.parameters.push({
            "key": "",
            "value": ""
        })
        setBuilder(builder)

        buildURL()

    }

    const removeParameter = (e, index) => {

        builder = { ...builder }
        builder.parameters.splice(index, 1)
        setBuilder(builder)

        buildURL()

    }

    const loadParametersTemplate = () => {

        builder = { ...builder }

        let values = [
            {
                "key": "utm_id",
                "value": ""
            },
            {
                "key": "utm_source",
                "value": ""
            },
            {
                "key": "utm_medium",
                "value": ""
            },
            {
                "key": "utm_campaign",
                "value": ""
            },
            {
                "key": "utm_term",
                "value": ""
            },
            {
                "key": "utm_content",
                "value": ""
            }
        ]

        values.map((item, index) => {
            builder.parameters.push(item)
        })

        setBuilder(builder)

        buildURL()

    }


    const eventHandler = () => {
        if(count > 0){
            axios.patch(window.REACT_APP_API_ENDPOINT + "/urls/count", {
                "count": count * 20
            })
        }
        count = 0
    }

    const debouncedEventHandler = useMemo(
        () => _.debounce(eventHandler, 1000)
      , []);

    const buildURL = () => {

        builder = { ...builder }

        let querystring = ""
        builder.parameters.map((item, index) => {
            if (item.key) {
                if (builder.encode_url) {
                    querystring += encodeURIComponent(item.key.trim()) + "=" + encodeURIComponent((item.value ? item.value.trim() : "")) + "&"
                } else {
                    querystring += item.key.trim() + "=" + (item.value ? item.value.trim() : "") + "&"
                }
            }
        })
        querystring = querystring.replace(/&\s*$/, "")

        builder.output_url = builder.input_url.split("?")[0] + (querystring ? "?" : "") + querystring

        if (builder.force_lowercase) {
            builder.output_url = builder.output_url.toLowerCase()
        }

        setBuilder(builder)

        count += 1
        debouncedEventHandler()
    }

    let [copyText, setCopyText] = useState("Copy to clipboard")

    const copy = () => {

        navigator.clipboard.writeText(builder.output_url)

        setCopyText("Copied!")

        setTimeout(() => {
            setCopyText("Copy to clipboard")
        }, 2000)

    }



    let history = useHistory()
    let [saved, setSaved] = useState(false)
    const saveURL = (e) => {

        e.preventDefault()
        setSaved(true)

        if (id) {
            axios.patch(window.REACT_APP_API_ENDPOINT + "/urls/" + id, {
                "name": builder.name,
                "campaign": builder.campaign,
                "input_url": builder.input_url,
                "output_url": builder.output_url,
                "parameters": builder.parameters,
                "force_lowercase": builder.force_lowercase,
                "encode_url": builder.encode_url
            }).then(res => {

                setTimeout(() => {
                    setSaved(false)
                }, 1000)

            })

        } else {
            axios.post(window.REACT_APP_API_ENDPOINT + "/urls", {
                "name": builder.name,
                "campaign": builder.campaign,
                "input_url": builder.input_url,
                "output_url": builder.output_url,
                "parameters": builder.parameters,
                "force_lowercase": builder.force_lowercase,
                "encode_url": builder.encode_url
            }).then(res => {
                history.push("/urls/" + res.data.id)
            })
        }

    }

    const deleteURL = () => {
        setLoading(true)
        axios.delete(window.REACT_APP_API_ENDPOINT + "/urls/" + id).then(res => {
            history.push("/urls")
            setLoading(false)
        })

    }

    let [QR, setQR] = useState(true)

    const GenerateQR = ({ url }) => {

        React.useEffect(() => {

            if (builder.output_url) {

                var canvas = document.getElementById('canvas')
                QRCode.toCanvas(canvas, url, function (error) {
                    if (error) console.error(error)
                })

            }

        }, [url])

        return (
            <canvas id="canvas" className={"d-block mt-3"} style={{ border: "solid 2px #E5E5E5" }}></canvas>
        )

    }

    return (
        <div className="container p-0 m-0 mx-auto">


            <div className="row p-0 m-0">
                <div className="col-12 col-lg-8 p-4 p-lg-5 bg-white shadow-sm mx-auto">

                    {!loading &&
                        <div className="container-fluid m-0 p-0">

                            {auth &&
                                <div className={"row " + (builder.output_url ? "" : "d-none")}>
                                    <div className="col-12">



                                        <div className="container-fluid m-0 p-0">
                                            <div className="row m-0 p-0">
                                                <div className="col-8 m-0 p-0">

                                                    <div className="input-group">
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                value={builder.name}
                                                                onChange={(e) => handleFieldChange(e, "name")}
                                                                placeholder="Untitled URL"
                                                                className="form-control px-3 py-2 border-0 fw-bold"
                                                                aria-label="Sizing example input"
                                                                aria-describedby="inputGroup-sizing-default" />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-4 m-0 p-0 text-end">

                                                    <button onClick={e => saveURL(e)} className={"btn btn-sm " + (saved ? "btn-success" : "btn-outline-success") + " me-2 px-3 py-2 d-inline"}>
                                                        <i className="bi bi-check-lg me-2"></i> {saved ? "URL updated!" : "Save URL"}
                                                    </button>

                                                    {id &&
                                                        <button onClick={e => deleteURL()} className={"btn btn-sm btn-outline-danger px-3 py-2 d-inline"}>
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <hr className="mb-4" />

                                    </div>
                                </div>
                            }

                            <div className="row m-0 p-0">
                                <div className="col m-0 p-0">

                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className={"form-control"}
                                            name={"input_url"}
                                            value={builder.input_url}
                                            placeholder="Base URL"
                                            onChange={e => handleFieldChange(e, "input_url")} />
                                        <label for="input_url">Base URL</label>
                                    </div>

                                </div>
                            </div>
                            {builder.parameters.map((item, index) => {
                                return (
                                    <div className="row mt-4" key={index}>
                                        <div className="col-5">

                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name={"key"}
                                                    value={builder.parameters[index].key}
                                                    placeholder="Parameter"
                                                    onChange={(e) => handleFieldChange(e, "parameters[" + index + "].key")} />
                                                <label for="parameter">Parameter</label>
                                            </div>

                                        </div>
                                        <div className={(auth ? "col-5" : "col-6") + " me-0 pe-0"}>

                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name={"value"}
                                                    value={builder.parameters[index].value}
                                                    placeholder="Value"
                                                    onChange={(e) => handleFieldChange(e, "parameters[" + index + "].value")} />
                                                <label for="value">Value</label>
                                            </div>


                                        </div>
                                        <div className={(auth ? "col-2" : "col-1") + " m-0 p-0 text-center"}>

                                            <button onClick={e => removeParameter(e, index)} className="btn btn-outline-white text-danger" tabindex="-1" style={{ position: "relative", top: "8px" }}>
                                                <i className="bi bi-x-lg" style={{ fontSize: "18px" }}></i>
                                            </button>

                                        </div>
                                    </div>
                                )
                            })}
                            <div className="row mt-4">
                                <div className="col-12 text-start">

                                    <button onClick={e => addParameter()} className="btn btn-sm btn-outline-secondary text-secondary mb-4 mb-lg-0 px-3 py-2 me-0 me-lg-4 d-block d-lg-inline">
                                        <i className="bi bi-plus-lg me-2"></i>
                                        Add parameter
                                    </button>

                                    <button onClick={e => loadParametersTemplate()} className="btn btn-sm btn-outline-secondary text-secondary px-3 py-2 me-0 me-lg-4 d-block d-lg-inline">
                                        <i className="bi bi-plus-lg me-2"></i>
                                        Add basic UTM parameters
                                    </button>

                                    {auth &&
                                        <ImportParameters builder={builder} setBuilder={setBuilder} buildURL={buildURL} />
                                    }

                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-start">

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={QR}
                                            id="qr_code"
                                            onChange={e => setQR(QR ? false : true)}
                                            checked={QR} />
                                        <label className="form-check-label" for="qr_code">
                                            Generate QR code
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={builder.force_lowercase}
                                            id="force_lower_check"
                                            onChange={e => toggleField(e, "force_lowercase")}
                                            checked={builder.force_lowercase} />
                                        <label className="form-check-label" for="force_lower_check">
                                            Force URLs to lowercase
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={builder.encode_url}
                                            id="encode_url_check"
                                            onChange={e => toggleField(e, "encode_url")}
                                            checked={builder.encode_url} />
                                        <label className="form-check-label" for="encode_url_check">
                                            URL encode querystring parameters
                                        </label>
                                    </div>

                                </div>
                            </div>
                            {auth &&
                                <div className={"row mt-4 " + (builder.output_url ? "" : "d-none")}>
                                    <div className="col-12">

                                        <hr />

                                        <div className="text-start mb-3">
                                            <strong>Campaign association</strong>
                                        </div>

                                        <AttachCampaign builder={builder} setBuilder={setBuilder} />

                                    </div>
                                </div>
                            }
                            <div className={"row mt-4 " + (builder.output_url ? "" : "d-none")}>
                                <div className="col-12 text-start">

                                    <hr />

                                    <div className="text-start mb-3">
                                        <strong>Final URL</strong>
                                        <br />
                                        <small>{builder.output_url && builder.output_url.length} characters</small>
                                    </div>

                                    <pre className="container-fluid bg-light p-3 mb-2 border-0 text-start" style={{ whiteSpace: "pre-wrap" }}>
                                        {builder.output_url}
                                    </pre>

                                    <button onClick={e => copy()} className={"btn btn-sm " + (copyText == "Copied!" ? "btn-secondary text-white" : "btn-outline-secondary text-secondary") + " px-3 py-2 me-0 me-md-3 d-block d-md-inline"}>
                                        <i className="bi bi-clipboard"></i> {copyText}
                                    </button>

                                    <div className={"mt-4 " + (QR ? "d-block" : "d-none")}>
                                        <strong>QR code</strong>
                                        <GenerateQR url={builder.output_url} />
                                    </div>


                                </div>
                            </div>

                        </div>
                    }

                    {loading &&
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    )

}

export default MiniBuilder;

const AttachCampaign = ({ builder, setBuilder }) => {

    React.useEffect(() => {

        getCampaigns()

    }, [])

    let [campaigns, setCampaigns] = useState()
    let [loading, setLoading] = useState()


    const getCampaigns = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/campaigns").then(res => {

            setLoading(false)
            setCampaigns(res.data)

        })

    }

    const handleFieldChange = (e) => {

        builder = { ...builder }
        builder.campaign = e.target.value
        setBuilder(builder)

    }

    return (
        <>

            <div className="form-floating">

                <select
                    type="text"
                    className={"form-control"}
                    name={"campaign"}
                    value={builder.campaign}
                    placeholder="Campaign"
                    onChange={(e) => handleFieldChange(e)} >

                    <option value="" selected>Select one</option>

                    {!loading && campaigns && campaigns.map((item, index) => {
                        return (
                            <option value={item.id}>{item.name}</option>
                        )
                    })}

                </select>

                <label for="campaign">Campaign</label>

            </div>

        </>

    )

}

const ImportParameters = ({ builder, setBuilder, buildURL }) => {

    let [channels, setChannels] = useState()
    let [loading, setLoading] = useState()

    React.useEffect(() => {

        getChannels()

    }, [])

    const getChannels = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/channels").then(res => {

            setLoading(false)

            res.data.map((item, index) => {
                res.data[index].selected = false
            })

            setChannels(res.data)

        })

    }

    const toggle = (e, index) => {

        channels = [...channels]

        channels.map((item, index2) => {
            channels[index2].selected = false
        })

        channels[index].selected = true
        setChannels(channels)

    }

    const importParameters = () => {

        builder = { ...builder }

        channels.map((item, index) => {
            if (item.selected) {

                item.parameters.map((item2, index2) => {
                    builder.parameters.push({
                        "key": item2["key"],
                        "value": item2["value"]
                    })
                })

            }
        })

        setBuilder(builder)
        buildURL()

    }

    return (
        <>
            <button onClick={e => getChannels()} className="btn btn-sm btn-outline-secondary text-secondary mb-4 mb-lg-0 px-3 py-2 me-0 me-lg-4 d-block d-lg-inline" data-bs-toggle="modal" data-bs-target="#importparameters">
                <i class="bi bi-plus-lg me-2"></i>
                Import from channel
            </button>

            <div className="modal fade" id="importparameters" tabindex="-1" aria-labelledby="importparametersLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="importparametersLabel">Import parameters from channel</h5>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {loading &&
                            <div className="modal-body text-center">
                                <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                            </div>
                        }

                        {!loading &&
                            <>
                                <div className="modal-body">

                                    <div className="container-fluid p-0 m-0 ">
                                        <div className="row p-0 m-0">

                                            <div className="col-12 mt-md-0" style={{ maxHeight: "500px", overflowY: "scroll" }}>

                                                {channels && channels.map((item, index) => {
                                                    return (
                                                        <div className="container-fluid m-0 p-0 striped">
                                                            <div className={"row m-0 p-0"}>
                                                                <div className="col-12 m-0 p-0">
                                                                    <div className="form-check" key={index}>
                                                                        <input className="form-check-input" type="radio" value={item.selected} checked={item.selected} onClick={e => toggle(e, index)} id={"checkbox-" + index} />
                                                                        <label className="form-check-label" for={"checkbox-" + index}>
                                                                            {item.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })

                                                }

                                                {channels && channels.length < 1 &&
                                                    <>
                                                        <strong>You have no saved channels to import.</strong>
                                                    </>
                                                }


                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button type="submit" onClick={e => importParameters()} data-bs-dismiss="modal" aria-label="Close" className="btn btn-outline-secondary mx-auto mx-md-0">{(channels && channels.length < 1) ? "Okay, got it" : "Import"}</button>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>

        </>
    )

}