import React, { useState } from "react"
import axios from "axios"

const URLCounter = () => {

    let [counter, setCounter] = useState()

    React.useEffect(() => {

        getCount()

        const timer = window.setInterval(() => {
            getCount()
        }, 10000)

        return () => {
            window.clearInterval(timer);
          };

    }, [])

    const getCount = () => {

        axios.get(window.REACT_APP_API_ENDPOINT + "/urls/count").then(res => {
            setCounter(res.data.count)
        })

    }

    return (
        <>{counter && parseInt(counter).toLocaleString("en-US")}</>
    )

};

export default URLCounter;


