import React, { useState } from "react"
import {
    Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import _ from "lodash";
import ReactGA from "react-ga"

import MiniBuilder from "../../components/mini-builder";
import URLCounter from "../../components/counter";

const HomeGuest = () => {

    React.useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Free campaign URL builder, querystring generator, and collaboration tool | Querystring</title>
            </Helmet>
            <div className="container-fluid m-0 p-0 bg-light">
                <div className="row m-0 p-0">
                    <div className="col-12 text-center pt-5 pb-3">
                        <h1>Campaign URL Builder</h1>
                        <h2 className="mt-2 fw-light">Build and parse querystrings for tracked campaign URLs</h2>

                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <span class="badge bg-light text-dark py-3 px-4 fw-light" style={{ fontSize: "16px" }}><strong className="fw-bold"><URLCounter /></strong> URLs generated</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-12 text-center pb-5">

                        <MiniBuilder auth={false} />

                    </div>
                </div>
            </div >

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center py-5">

                        <h3>Ready for the next level? It's free.</h3>

                        <div className="container py-5 mx-auto">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">

                                    <i class="bi bi-people text-primary" style={{ fontSize: "48px" }}></i>
                                    <br />
                                    <h5 className="text-primary">Invite your team</h5>
                                    <p>Manage your organization's campaign URLs all in one place - bring your team along with you.</p>

                                </div>
                                <div className="col-12 col-md-6 col-lg-4">

                                    <i class="bi bi-star text-primary" style={{ fontSize: "48px" }}></i>
                                    <br />
                                    <h5 className="text-primary">Campaign management</h5>
                                    <p>Organize your saved URLs & querystrings by campaign.</p>

                                </div>
                                <div className="col-12 col-md-6 col-lg-4">

                                    <i class="bi bi-collection text-primary" style={{ fontSize: "48px" }}></i>
                                    <br />
                                    <h5 className="text-primary">Channel templates</h5>
                                    <p>Don't repeat yourself with channel templates that maintain consistency across campaigns & URLs.
                                    </p>

                                </div>
                            </div>
                        </div>


                        <Link to="/sign-up" className={"btn bgn-lg btn-primary text-white px-3 py-2 d-inline"}>
                            Sign up
                        </Link>

                    </div>
                </div>
            </div>

        </>
    )

}

export default HomeGuest;


