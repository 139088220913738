import React, { useState, useContext } from "react"
import axios from "axios"
import moment from "moment"
import { Link, useHistory } from "react-router-dom"
import UserContext from "../../context/user"
import ReactGA from "react-ga"
import loading_animation from "../../assets/loading.svg"

const Campaigns = () => {

    let [loading, setLoading] = useState(false)

    const [Auth_user, setAuth_user] = useContext(UserContext);

    const history = useHistory();

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <>
            <div className="container-fluid m-0 py-5 bg-light">
                <div className="row">
                    <div className="col-12">

                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <h2>Campaigns</h2>
                                </div>
                                <div className="col-6 text-end">
                                    <CreateCampaign />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="container mx-auto p-0 bg-white shadow-sm mt-4">
                            <div className="row">
                                <div className="col-12 p-3">
                                    <ListCampaigns />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default Campaigns;





const CreateCampaign = () => {

    let [loading, setLoading] = useState(false)
    let [name, setName] = useState()

    React.useEffect(() => {


    }, [])

    const handleNameChange = (e) => {

        setName(e.target.value)

    }

    let history = useHistory()

    const createCampaign = (e, key) => {

        e.preventDefault()

        axios.post(window.REACT_APP_API_ENDPOINT + "/campaigns", {
            "name": name
        }).then(res => {
            history.push("/campaigns/" + res.data.id)
        })

    }

    return (
        <>


            <button className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#campaignModal"><i className="bi bi-plus-lg me-1"></i> Create a new campaign</button>


            <div className="modal fade" id="campaignModal" tabindex="-1" aria-labelledby="campaignModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "450px" }}>
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="campaignModalLabel">Create new campaign</h5>

                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <form onSubmit={e => createCampaign(e)}>

                            <div className="modal-body">

                                <div className="container-fluid p-0 m-0 ">
                                    <div className="row p-0 m-0">

                                        <div className="col-12 mt-md-0">

                                            <div className="form-floating">

                                                <input
                                                    type="text"
                                                    className={"form-control"}
                                                    name={"name"}
                                                    value={name}
                                                    required
                                                    placeholder="Campaign name"
                                                    onChange={(e) => handleNameChange(e)} />

                                                <label for="name">Campaign name</label>

                                            </div>


                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">
                                <button type="submit" data-bs-dismiss="modal" className="btn btn-outline-secondary mx-auto mx-md-0">Create campaign</button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>

        </>
    )

}

const ListCampaigns = () => {

    let [loading, setLoading] = useState(false)
    let [campaigns, setCampaigns] = useState()

    React.useEffect(() => {

        getCampaigns()

    }, [])

    const getCampaigns = () => {

        setLoading(true)

        axios.get(window.REACT_APP_API_ENDPOINT + "/campaigns").then(res => {

            setLoading(false)
            setCampaigns(res.data)

        })

    }

    let history = useHistory()
    const viewRecord = (e, id) => {
        history.push("/campaigns/" + id);
    }

    let [search, setSearch] = useState()

    const searchFilter = (e) => {
        setSearch(e.target.value)
    }

    return (
        <>
            {!loading && campaigns && campaigns.length > 0 &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 pb-4 pt-3">

                            <input id="search" type="text" placeholder="Search" className="form-control p-2 ps-3 bg-light" value={search} onChange={e => searchFilter(e)} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <div className="nowrap" style={{
                                overflowX: "auto"
                            }}>

                                <table className={"table table-hover"}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="fw-light">Name</th>
                                            <th scope="col" className="fw-light">Last modified by</th>
                                            <th scope="col" className="fw-light">Last modified</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {campaigns && campaigns.filter(function (campaign) {
                                            if(search){
                                                return (
                                                    campaign.name.toLowerCase().indexOf(((search ? search : "")).toLowerCase().trim()) > -1
                                                )
                                            } else {
                                                return 1 === 1
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <tr style={{ cursor: "pointer" }} onClick={e => viewRecord(e, item.id)}>
                                                    <td className="text-primary">{item.name}</td>
                                                    <td>{item.updated_by_email}</td>
                                                    <td>{moment.utc(item.updated_at).local().fromNow()}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            }
            {loading &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={loading_animation} className="col-12 col-md-2" style={{ height: "100%", maxHeight: "70px" }}></img>
                        </div>
                    </div>
                </div>
            }
            {!loading && campaigns && campaigns.length < 1 &&
                <div className="container-fluid mt-2">
                    <div className="row">
                        <div className="col-12 bg-light p-5 text-center">
                            No campaigns have been created yet.
                            <br />
                            <br />
                            <CreateCampaign />
                        </div>
                    </div>
                </div>
            }
        </>
    )

}